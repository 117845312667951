@import '../../../../assets/styles/variable';

.bp__card {
  &--caption {
    align-items: center;
    color: #54545680;
    display: flex;
    font-family: $font-tstar-seq;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-between;
    line-height: 14px;
    text-transform: uppercase;

    .bp__action-button {
      font-family: $font-tstar-seq;

      &.bp__ab--round-corner {
        border-radius: 8px;
        font-size: 12px;
        height: 25px;
        margin: 0;
        padding: 2px 8px 0;
      }
    }
  }
}

.bp__card--system-profile {
  .sp {
    &__profile {
      &:not(:first-child) {
        border-top: 1px solid $light-border;
        margin-top: 16px;
        padding-top: 16px;
      }

      &--active {
        .sp__profile--title {
          &.atom-design--t3 {
            gap: 0 8px;
            grid-template-columns: 20px auto 20px !important;
          }
        }
      }

      &--processing,
      &--pending {
        .sp__profile--title {
          &.atom-design--t3 {
            gap: 0 8px;
            grid-template-columns: 15px auto 20px;
          }
        }
      }

      .bp__action-button {
        border-radius: 8px;
        font-size: 10px;
        height: 25px;
        margin: 6px 0 0;
        padding: 2px 10px;
        text-transform: uppercase;
      }

      &--title {
        &.atom-design--t3 {
          gap: 0;
          grid-template-columns: auto 20px;
          margin: 0;

          .com__ad__details {
            .com__ad--title {
              font-size: 16px;
              font-weight: 500;
              line-height: 17px;
              margin: 3px 0 0;

              &-prefix {
                background-color: $color-error;
                border-radius: 14.5px;
                color: $light-color1;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                margin-left: 4px;
                margin-top: -15px;
                padding: 0 6px;
              }

              &-prefix-space {
                margin-left: 4px;
                margin-right: 4px;
              }

              .com__ad--info {
                font-weight: 400;
              }
            }
          }

          .com__ad__action {
            .bp__icon {
              height: 20px;
            }
          }
        }

        .icon-clock-circular {
          width: 15px;
        }
      }

      .evs {
        &--ev-chargers {
          .evs--ev-charger {
            &:not(:first-child) {
              margin-top: 16px;
            }

            .com__ad__action {
              .bp__icon {
                svg {
                  height: 24px;
                  width: 24px;
                }
              }
            }

            .com__ad__charger-title {
              align-items: flex-start;
            }

            .com__ad__charger-subtitle {
              display: flex;
              flex-direction: column;

              .com__ad__charger-mode{
                color: $color-error;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
              }
            }

            .com__ad__action-button{
              justify-self: flex-end;
            }
          }
        }
      }
    }

    &__desc {
      color: $light-color3;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      margin-top: 6px;
    }
  }

  .evs {
    &--ev-charger {
      &.atom-design--t3 {
        gap: 0 8px;

        .com__ad__details {
          position: relative;
        }

        .com__ad--title {
          color: $light-color6;
        }

        .com__ad--sub-title {
          color: #ff5a5f;
        }

        .db__value {
          bottom: -15px;
          position: absolute;
          width: max-content;
        }

        .com__ad__icon {
          .bp__icon {
            svg {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }

  .bp__card--caption {
    align-items: flex-end;

    button {
      text-transform: uppercase;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__card {
    &--caption {
      color: $dark-color2;
    }
  }

  .sp__profile {
    .com__ad--title {
      color: $light-color1;
    }

    .sp__desc {
      color: $dark-color2;
    }
  }

  .bp__card--system-profile {
    .evs {
      &--ev-charger {
        .com__ad--title {
          color: $dark-color1;
        }

        .com__ad__icon {
          svg g path {
            fill: $dark-color1;
            stroke: $dark-color1;
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */
.orange-theme {
  .bp__card {
    &--caption {
      .bp__action-button {
        color: $color-orange;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web view
 * *********************** *********************** *********************** */

.web-view {
  section.bp__card-view {
    margin-top: 16px;
  }

  .bp__card {
    &--caption {
      font-size: 16px;
      line-height: 20px;

      .bp__action-button {
        align-items: center;
        display: flex;
        font-size: 15px;
        justify-content: center;
        padding: 23px 25px 20px;

        &.bp__ab--round-corner {
          border-radius: 25px;
        }
      }
    }
  }

  .bp__card--system-profile,
  .bp__system-profile--card {
    .sp {
      &__profile {
        padding: 32px;

        &:not(:first-child) {
          margin-top: 0;
        }

        &--title {
          &.atom-design--t3 {
            .com__ad__details {
              .com__ad--title {
                font-size: 20px;
              }
            }
          }
        }

        &--active,
        &--processing,
        &--pending {
          .sp__profile--title {
            &.atom-design--t3 {
              gap: 0 16px;
              grid-template-columns: 24px auto 24px !important;

              .com__ad__icon {
                svg {
                  height: 24px;
                  width: 24px;
                }
              }
            }
          }
        }

        .bp__action-button {
          font-size: 12px;
          margin: 9px 0 0;
        }
      }

      &__desc {
        font-size: 15px;
        margin-top: 8px;
      }
    }
  }
}
