@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.bp__time-keeper {
  background: $light-overlay;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100;

  .react-timekeeper {
    border-radius: 4px;
    box-shadow: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &__tb-hour,
    &__tb-minute {
      font-weight: 500;

      &--active {
        color: var(--top-selected-color, $color-blue) !important;
      }
    }

    &__tb-meridiem {
      color: var(--top-meridiem-color, $color-blue);
    }

    &__hand-circle-outer {
      fill: var(--hand-circle-outer, $color-blue);
    }

    &__clock-hours,
    &__clock-minutes {
      span {
        color: var(--numbers-text-color, #000);
      }
    }

    &__meridiem {
      &--active {
        background-color: var(--meridiem-selected-bg-color, $color-blue) !important;
        color: var(--meridiem-selected-text-color, #fff);
      }

      &-toggle {
        &.css-7cuz4l,
        &.css-ktukdg,
        &.css-xbtxlx-Meridiems,
        &.css-wufnqa-Meridiems {
          background: var(--meridiem-selected-bg-color, $color-blue);
          color: var(--meridiem-selected-text-color, #fff);
        }
      }
    }

    &__done-btn {
      background-color: #f4f4f4;
      border-radius: 0 0 4px 4px;
      border-top: 1px solid #ccc;
      color: #68687f;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      padding: 16px 0;
      text-align: center;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__time-keeper {
    background: $dark-overlay;

    .react-timekeeper {
      background: var(--main-bg, $dark-bg1);

      &__tb-hour,
      &__tb-minute {
        color: var(--top-text-color, $dark-color2);
      }

      &__top-bar {
        background: var(--top-bg, $dark-bg1);
      }

      &__clock-wrapper {
        background: var(--clock-wrapper-bg, $dark-bg2);
      }

      &__clock {
        background: var(--clock-bg, $dark-bg1);
      }

      &__clock-hours,
      &__clock-minutes {
        span {
          color: var(--numbers-text-color, $dark-color1);
        }
      }

      &__meridiem-toggle {
        background: var(--meridiem-bg-color, $dark-bg1);
        color: var(--meridiem-bg-color, $dark-color1);

        &.css-7cuz4l,
        &.css-ktukdg,
        &.css-xbtxlx-Meridiems,
        &.css-wufnqa-Meridiems {
          background: var(--meridiem-selected-bg-color, $color-blue);
          color: var(--meridiem-selected-text-color, #fff);
        }
      }

      &__done-btn {
        background-color: $dark-bg2;
        border-color: $dark-border;
        @extend %color-blue;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme {
  .bp__time-keeper {
    .react-timekeeper {
      &__tb-hour,
      &__tb-minute {
        &--active {
          color: var(--top-selected-color, $color-orange) !important;
        }
      }

      &__tb-meridiem {
        color: var(--top-meridiem-color, $color-orange);
      }

      &__hand-circle-outer {
        fill: var(--hand-circle-outer, $color-orange);
      }

      &__meridiem {
        &--active {
          background-color: var(--meridiem-selected-bg-color, $color-orange) !important;
        }
      }

      &__meridiem-toggle {
        &.css-7cuz4l,
        &.css-ktukdg,
        &.css-xbtxlx-Meridiems,
        &.css-wufnqa-Meridiems {
          background: var(--meridiem-selected-bg-color, $color-orange);
        }
      }
    }
  }
}
