@import '../../../../assets/styles/variable';

.bp__storm-alerts {
  .sa {
    &__wrapper {
      padding: 32px 0 10px;
    }

    &__heading {
      align-items: center;
      color: $light-color8;
      display: grid;
      font-family: $font-tstar-seq;
      font-size: 12px;
      font-weight: 700;
      grid-auto-flow: column;
      justify-content: flex-start;
      letter-spacing: 0.05em;
      line-height: 16px;
      text-transform: uppercase;
    }

    &__info {
      cursor: pointer;
      margin-left: 5px;
      margin-top: -2px;
    }
  }

  .sa__item {
    background-color: $light-color1;
    border-radius: 8px;
    box-shadow: 1px 2px 10px 0 rgb(63 67 71 / 8%);
    color: $light-color2;
    margin-top: 16px;
    padding: 16px;

    .sa__item__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-left: 0 !important;
      text-transform: capitalize;
    }

    .sa__item__desc {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;

      &:last-child {
        margin-left: 5px;
        text-transform: uppercase;
      }
    }

    .atom-design--t1 {
      grid-template-columns: auto 80px;

      .com__ad__value {
        color: $light-color8;
        line-height: 12px;
        margin-top: -3px;
      }
    }

    .com__ad__action {
      place-self: flex-start flex-end;

      .bp__action-button.bp__ab--round-corner {
        border-radius: 8px;
        font-family: $font-tstar-seq;
        font-size: 12px;
        height: 26px;
        margin: 0;
        min-width: 60px;
        padding: 2px 8px 0;
        text-transform: uppercase;
      }

      .bp__ab--disabled {
        border-color: #babec3 !important;
        color: #babec3 !important;
      }

      .ib__btn--loading {
        border: none;
        min-width: auto !important;
        padding: 0 !important;

        .bp__icon {
          svg {
            height: 24px;
          }
        }
      }
    }

    .sa__status-info {
      margin-top: 4px;

      .sa__status-text {
        color: $light-color8;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        &--active {
          color: $color-error;
        }
      }
    }

    .sa__time-info {
      align-items: center;
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: flex-start;
      margin-top: 8px;

      > div {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .sa__time-label {
        color: $light-color8;
      }

      .sa__time-value {
        color: #545456;
      }
    }
  }

  .sa__no-alert {
    .com__ad--title {
      color: $light-color8;
      font-size: 12px;
      line-height: 16px;
      margin-top: 2px;
    }
  }

  .sa__shimmer {
    background-color: #e1e1e1;
    border-radius: 8px;
    height: 55px;
    margin-top: 16px;
    width: 100%;
  }
}

.sa {
  &__popup__wrapper {
    padding: 20px 20px 35px;

    .bp_reference_text {
      color: #7b8793b2;
    }
  }

  &__info-popup {
    [role='button'].bp__link {
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 16px;

      .bp__icon {
        margin-left: 5px;
      }
    }

    .bp__status-text--opted-out {
      color: $light-color8;
    }
  }

  &__change-popup {
    .e_dialog__wrapper {
      max-width: 450px;
    }

    &.ca__optout-popup {
      &.ca__optout-popup__web {
        .e_dialog__wrapper {
          max-width: 450px;
        }
      }

      .e_dialog__wrapper {
        max-width: 300px;

        .e_dialog__body {
          .e_dialog__body__wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 24px 16px;

            .e_dialog__content {
              margin-top: 24px;
              text-align: center;
            }
          }
        }

        .e_dialog__footer {
          border-top: none;

          .e_dialog__footer__wrapper {
            padding: 0 16px 16px;

            .bp__action-button {
              background-color: $color-blue !important;
              border-radius: 8px;
              margin: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  .bp__storm-alerts {
    .sa {
      &__wrapper {
        border-radius: 0 0 8px 8px;
      }

      &__heading {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .sa__item {
      padding: 32px;

      .sa__item__title {
        font-size: 20px;
        line-height: 28px;
      }

      .sa__item__desc {
        font-size: 12px;
        line-height: 16px;
      }

      .atom-design--t1 {
        grid-template-columns: auto 120px;

        .com__ad__value {
          margin-top: 0;
        }
      }

      .com__ad__action {
        .bp__action-button.bp__ab--round-corner {
          font-size: 16px;
          height: 32px;
          line-height: 20px;
          min-width: 100px;
        }

        .ib__btn--loading {
          .bp__icon {
            svg {
              height: 32px;
            }
          }
        }
      }

      .sa__status-info {
        margin-top: 8px;

        .sa__status-text {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .sa__time-info {
        gap: 32px;

        .sa__time-label {
          font-size: 16px;
          line-height: 20px;
        }

        .sa__time-value {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .sa__no-alert {
      .com__ad--title {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .sa__shimmer {
      height: 75px;
      padding: 24px;
    }
  }

  .sa {
    &__info-popup {
      [role='button'].bp__link {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  &.dark-theme {
    .bp__storm-alerts {
      border-color: $dark-border;

      .sa {
        &__wrapper {
          background-color: $dark-bg1;
        }
      }

      .sa__item {
        background-color: $dark-bg2;
      }
    }

    .sa__shimmer {
      background-color: $dark-bg2;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__storm-alerts {
    .sa {
      &__heading {
        color: $dark-color2;
      }
    }

    .sa__item {
      background-color: $dark-bg1;

      .sa__item__desc {
        color: $light-color6;
      }

      .sa__time-info {
        .sa__time-label {
          color: $dark-color2;
        }

        .sa__time-value {
          color: $dark-color1;
        }
      }
    }

    .sa__no-alert {
      .com__ad--title {
        color: $dark-color2;
      }
    }

    .sa__shimmer {
      background-color: $dark-bg1;
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

// .orange-theme {}
