@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.yt-video {
  position: relative;

  iframe {
    border-radius: 10px;
    height: 215px;
    width: 100%;
  }
}

.bp__video-dialog {
  .e_dialog__wrapper {
    width: calc(100% - 16px);

    .e_dialog__header {
      padding: 16px 12px 12px;
    }

    .e_dialog__body {
      height: 231px;
      padding: 8px;

      .bp-select-profile-video {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  &--hidden {
    .e_dialog__wrapper {
      visibility: hidden;
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  .bp__video-dialog {
    .yt-video {
      iframe {
        height: 360px;
        width: 640px;
      }
    }

    .e_dialog__wrapper {
      width: 688px;

      .e_dialog__header {
        padding: 18px 24px 16px;
      }

      .e_dialog__body {
        height: 408px;
        padding: 24px;
      }
    }
  }
}
